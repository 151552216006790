import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface IntersectingComponentModel {
	preload?: string;
	threshold?: number;
	timeout?: number;
	configId?: string;
	horizontal?: boolean;
	disabled?: boolean;
	clsComponentKeys?: string[];
}

// Props -------------------------------------------------------------------------------------------

export const IntersectingComponentProps: Props<IntersectingComponentModel> = {
	preload: {
		type: String as PropType<IntersectingComponentModel['preload']>,
	},
	threshold: {
		type: Number as PropType<IntersectingComponentModel['threshold']>,
	},
	timeout: {
		type: Number as PropType<IntersectingComponentModel['timeout']>,
	},
	configId: {
		type: String as PropType<IntersectingComponentModel['configId']>,
	},
	horizontal: {
		type: Boolean as PropType<IntersectingComponentModel['horizontal']>,
		default: false,
	},
	disabled: {
		type: Boolean as PropType<IntersectingComponentModel['disabled']>,
	},
	clsComponentKeys: {
		type: Array as PropType<IntersectingComponentModel['clsComponentKeys']>,
		default: () => [],
	},
};
